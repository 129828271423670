const data = {
    logo: ['Onlayn tur','Qırmızı qəsəbə'],
    exit: 'Çıx',
    trial: 'Sınaq versiyası',
    trialEnd: 'Sınaq versiyası tamamlandı',
    pay: 'Ödəmək',
    authTitle: 'Daxil ol',
    email: 'E-poçt',
    emailPlaceholder: 'E-poçtu daxil edin',
    btnLogin: 'Daxil ol',
    infoLogin: 'Saytdakı düymələri klikləməklə, şəxsi məlumatlarınızın emalına razılıq verirsiniz. Siz şəxsi məlumatların emalı ilə razılaşırsınız.',
    alertLogin: 'Siz e-poçt vasitəsilə giriş linki alacaqsınız. Daxil olmaq üçün linki izləyin.',
    linkPayments: 'Ödənişlər',
    titlePayments: 'Ödənişlər',
    linkLogin: 'Daxil ol',
    tablePaymentId: 'ID',
    tablePaymentSum: 'məbləğ',
    tablePaymentCreatedAt: 'Yaradıldı',
    viewTour: 'Baxış turu',
};

export default data;