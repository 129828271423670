const data = {
    logo: ['Онлайн тур по','красной слободе'],
    exit: 'Выход',
    trial: 'Пробная версия онлайн тура',
    trialEnd: 'Просмотр тура недоступен',
    pay: 'Оплатить',
    authTitle: 'Авторизация',
    email: 'Почта',
    emailPlaceholder: 'Введите ваш email',
    btnLogin: 'Войти',
    infoLogin: 'Нажимая кнопки на сайте Вы даете свое согласие на обработку Ваших персональных данных. Вы соглашаетесь на обработку персональных данных.',
    alertLogin: 'Вам на почту придет ссылка для входа. Перейдите по ссылке, чтобы войти в систему.',
    linkPayments: 'Платежи',
    linkLogin: 'Войти',
    titlePayments: 'Платежи',
    tablePaymentId: 'ID',
    tablePaymentSum: 'Сумма',
    tablePaymentCreatedAt: 'Дата создания',
    viewTour: 'Посмотреть тур',
};

export default data;