import React, {useState} from "react"
import useInterval from "../../hooks/useInterval";

const Slider = ({ images }) => {
    const [index, setIndex] = useState(0)

    useInterval(() => {
        setIndex((index < (images.length - 1)) ? (index + 1) : 0)
    }, 10000);

    return (
        <div className="slider">
            <div className="slider--content">
                {
                    images.map((url, i) => {
                        //(index === i+1) ? 'prev' :
                        return <div key={i} className={"slider " + (index === i ? 'active' : '')} style={{backgroundImage: `url(${url})`}}></div>
                    })
                }
            </div>
        </div>
    )
}

export default Slider