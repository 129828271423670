const data = {
    logo: ['Online tour by', 'Red Village'],
    exit: 'Exit',
    trial: 'Trial version',
    trialEnd: 'Trial version finished',
    pay: 'Pay',
    authTitle: 'Login',
    email: 'Email',
    emailPlaceholder: 'Input email',
    btnLogin: 'Login',
    infoLogin: 'By clicking the buttons on the site, you consent to the processing of your personal data. You agree to the processing of personal data.',
    alertLogin: 'You will receive a login link by email. Follow the link to log in.',
    linkPayments: 'Payments',
    linkLogin: 'Login',
    titlePayments: 'Payments',
    tablePaymentId: 'ID',
    tablePaymentSum: 'Amount',
    tablePaymentCreatedAt: 'Created at',
    viewTour: 'View tour',
};

export default data;