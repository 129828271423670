import './App.css';

import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

import {useEffect, useState} from "react";
import {ru, en, az} from './translations'
import Iframe from "./components/iframe/iframe";
import Slider from "./components/slider/Slider";

function App() {
    const [lang, setLang] = useState('ru');
    const [enabledTour, setEnabledTour] = useState(false);
    const translations = {ru, en, az};

    useEffect(() => {
        const firebaseConfig = {
            apiKey: "AIzaSyA_9X15QY2SFmA7gnlgyjLmbxQYbiHFmV4",
            authDomain: "museum-233607.firebaseapp.com",
            projectId: "museum-233607",
            storageBucket: "museum-233607.appspot.com",
            messagingSenderId: "929479257879",
            appId: "1:929479257879:web:601d30a16acc1361759152",
            measurementId: "G-R87XJPGJM2"
        };

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);
    }, []);

    useEffect(() => {
        const navLang = navigator.language;
        if (navLang) {
            const l = navLang.split('-')[0];
            if (l === 'ru' || l === 'en' || l === 'az') {
                setLang(l);
            }
        }
    }, []);

    const showTour = () => {
        setEnabledTour(true);
        window.ym(98680966,'reachGoal','view_tour');
    };

    const bg1 = `${process.env.PUBLIC_URL}/images/bg-1.jpg`;

    return (
        <div className="App">
            <header className="App-header">
                <div className="container">
                    <div className="main">
                        <div className="logo">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 114.253" width="38" height="48.23">
                                <g fill="#bf9b13" transform="translate(0.127)">
                                    <path
                                        d="M80.419,29.421,72.035,20.4l-6.288,2.669L44.216,0,31.64,12.963,27.765,9.849l-10.1,10.866-1.651-.508-8.7,9.214H.2v1.652H8.076l8.447-8.96,1.651.508,9.781-10.485,3.874,2.987L44.216,2.415,65.3,25.036l6.288-2.669,8.13,8.706H89.946V29.421Z"
                                        transform="translate(-0.2)"></path>
                                    <path
                                        d="M63.66,29.576,59.214,30.72,54.323,25.7,42.128,38.79H33.3v1.652h9.527l11.56-12.328,4.319,4.448,4.382-1.144,8.257,9.023h7.622V38.79H72.044Z"
                                        transform="translate(-12.277 -9.369)"></path>
                                    <path
                                        d="M88.158,100.791a46.705,46.705,0,0,1-42.3,29.548v-5.973a40.459,40.459,0,0,0,35.759-23.384l.191-.381H79.965l-.064.127a38.842,38.842,0,0,1-33.98,21.923v-5.91a32.336,32.336,0,0,0,27.057-15.7l.254-.445H71.263l-.064.127a30.887,30.887,0,0,1-25.279,14.3V100.6H44.27v14.425a30.731,30.731,0,0,1-25.279-14.3l-.064-.127H16.958l.254.445a32.42,32.42,0,0,0,27.057,15.7v5.973a39.269,39.269,0,0,1-34.107-21.923l-.064-.127H8.257l.191.381a40.459,40.459,0,0,0,35.759,23.384V130.4a46.608,46.608,0,0,1-42.3-29.548l-.064-.191H0l.127.381a48.27,48.27,0,0,0,44.079,30.946v6.672h1.651v-6.672a48.15,48.15,0,0,0,44.016-31.01L90,100.6H88.158Z"
                                        transform="translate(0 -36.674)"></path>
                                </g>
                                <g fill="#191817" transform="translate(0.127 34.505)">
                                    <path
                                        d="M8.711,62.307H7.441V57.159A13.307,13.307,0,0,1,7.5,55.634h0a6.6,6.6,0,0,1-.191.763l-2.35,5.91H3.947L1.6,56.461a6.6,6.6,0,0,1-.191-.763h0c0,.318.064.826.064,1.525v5.084H.2V54.364H2.042l2.1,5.274c.127.381.254.7.318.89h0c.127-.445.254-.7.318-.953l2.1-5.274H8.647v8.007Z"
                                        transform="translate(-0.2 -54.3)"></path>
                                    <path
                                        d="M19.647,62.434a3.682,3.682,0,0,1-2.731-1.08A3.947,3.947,0,0,1,15.9,58.494a4.356,4.356,0,0,1,1.08-3.05A3.684,3.684,0,0,1,19.838,54.3a3.537,3.537,0,0,1,2.668,1.08,4.05,4.05,0,0,1,1.016,2.859,4.324,4.324,0,0,1-1.016,3.05A3.779,3.779,0,0,1,19.647,62.434Zm.064-7.053a2.166,2.166,0,0,0-1.778.826,3.388,3.388,0,0,0-.7,2.16,3.068,3.068,0,0,0,.635,2.1,2.317,2.317,0,0,0,3.493.064,3.992,3.992,0,0,0,0-4.321A1.793,1.793,0,0,0,19.711,55.38Z"
                                        transform="translate(-5.928 -54.3)"></path>
                                    <path
                                        d="M36.124,59.1c0,2.224-1.08,3.3-3.176,3.3-2.032,0-3.049-1.08-3.049-3.177V54.4h1.334v4.575c0,1.525.572,2.351,1.778,2.351,1.143,0,1.715-.763,1.715-2.224V54.464h1.334V59.1Z"
                                        transform="translate(-11.036 -54.336)"></path>
                                    <path
                                        d="M49.233,62.343H47.772L44.024,56.5a1.593,1.593,0,0,1-.254-.445h-.064c0,.191.064.508.064,1.08v5.211H42.5V54.4h1.588l3.62,5.655c.127.254.254.381.318.508h0a4.55,4.55,0,0,1-.064-1.08V54.4h1.27Z"
                                        transform="translate(-15.633 -54.336)"></path>
                                    <path d="M60.843,55.544H58.557v6.8H57.223v-6.8H55V54.4h5.843Z"
                                          transform="translate(-20.194 -54.336)"></path>
                                    <path
                                        d="M70.731,62.343H69.27l-.7-2.033H65.459l-.7,2.033H63.3L66.285,54.4h1.461Zm-2.541-3.114-1.08-3.177a2.883,2.883,0,0,1-.127-.508h0c-.064.191-.064.381-.127.508l-1.08,3.177Z"
                                        transform="translate(-23.222 -54.336)"></path>
                                    <path d="M78.034,62.343H76.7V54.4h1.334Z"
                                          transform="translate(-28.111 -54.336)"></path>
                                    <path
                                        d="M88.533,62.343H87.072L83.324,56.5a1.593,1.593,0,0,1-.254-.445h-.064c0,.191.064.508.064,1.08v5.211H81.8V54.4h1.524l3.62,5.655c.127.254.254.381.318.508h0a4.549,4.549,0,0,1-.064-1.08V54.4h1.27v7.943Z"
                                        transform="translate(-29.972 -54.336)"></path>
                                    <path
                                        d="M102.53,59.447a3.149,3.149,0,0,1-.7,2.16,2.349,2.349,0,0,1-1.842.763,3.309,3.309,0,0,1-.889-.127V61.036a1.588,1.588,0,0,0,.826.191c.889,0,1.334-.7,1.334-2.033V54.3h1.334v5.147Z"
                                        transform="translate(-36.284 -54.3)"></path>
                                    <path
                                        d="M111.946,62.343H107.5V54.4h4.255v1.144h-2.922v2.224h2.731v1.08h-2.731V61.2h3.112Z"
                                        transform="translate(-39.349 -54.336)"></path>
                                    <path
                                        d="M126.27,54.4l-2.159,7.943H122.65l-1.524-5.528a3.191,3.191,0,0,1-.127-.763h0a3.19,3.19,0,0,1-.127.763l-1.524,5.528h-1.524L115.6,54.4h1.461l1.461,5.783a3.19,3.19,0,0,1,.127.763h0a4.7,4.7,0,0,1,.127-.763l1.651-5.783h1.334l1.524,5.846c.064.191.064.445.127.7h0a3.28,3.28,0,0,1,.127-.7l1.4-5.846Z"
                                        transform="translate(-42.304 -54.336)"></path>
                                    <path
                                        d="M133.564,61.989V60.654a2.688,2.688,0,0,0,.445.318c.191.064.318.191.508.254a1.854,1.854,0,0,0,.572.127,1.578,1.578,0,0,0,.508.064,2.007,2.007,0,0,0,1.207-.254.944.944,0,0,0,.381-.826,1.2,1.2,0,0,0-.127-.508,1.079,1.079,0,0,0-.381-.381,2.573,2.573,0,0,0-.572-.318,7.441,7.441,0,0,0-.7-.318c-.254-.127-.508-.318-.762-.445a2.488,2.488,0,0,1-.635-.508,1.319,1.319,0,0,1-.381-.635,1.612,1.612,0,0,1-.127-.763,1.532,1.532,0,0,1,.254-.953,3.331,3.331,0,0,1,.635-.7,2.15,2.15,0,0,1,.953-.381,6.257,6.257,0,0,1,1.08-.127,4.018,4.018,0,0,1,1.778.254v1.271a2.889,2.889,0,0,0-1.715-.508,1.757,1.757,0,0,0-.572.064,4.375,4.375,0,0,0-.508.191,1.684,1.684,0,0,0-.381.318.685.685,0,0,0-.127.508.919.919,0,0,0,.127.445,1.685,1.685,0,0,0,.318.381,3.546,3.546,0,0,0,.508.318,7.454,7.454,0,0,0,.7.318c.254.127.508.318.762.445a2.487,2.487,0,0,1,.635.508,2.281,2.281,0,0,1,.445.635,1.76,1.76,0,0,1,.191.826,1.733,1.733,0,0,1-.254,1.017,3.329,3.329,0,0,1-.635.7,2.15,2.15,0,0,1-.953.381,7,7,0,0,1-1.143.127h-.508c-.191,0-.381-.064-.572-.064-.191-.064-.381-.064-.572-.127A1.4,1.4,0,0,0,133.564,61.989Z"
                                        transform="translate(-48.835 -54.3)"></path>
                                </g>
                                <g fill="#191817" transform="translate(0.127 46.006)">
                                    <path
                                        d="M15.316,86.843H13.03V77.756c0-.763.064-1.652.127-2.732h-.064a5.912,5.912,0,0,1-.381,1.334L8.52,86.907H6.933L2.677,76.422a8.181,8.181,0,0,1-.381-1.4h0c.064.572.064,1.462.064,2.732v9.087H.2V72.8H3.5l3.684,9.4c.254.7.445,1.271.572,1.589h.064c.254-.763.445-1.271.572-1.652L12.141,72.8h3.176Z"
                                        transform="translate(-0.2 -72.546)"></path>
                                    <path
                                        d="M42.288,81.188c0,3.94-1.842,5.91-5.589,5.91-3.557,0-5.4-1.906-5.4-5.719V72.8h2.35v8.134c0,2.732,1.08,4.13,3.239,4.13,2.1,0,3.112-1.334,3.112-4V72.8h2.35v8.388Z"
                                        transform="translate(-11.547 -72.546)"></path>
                                    <path
                                        d="M54.9,86.189V83.774a2.284,2.284,0,0,0,.762.508,7.655,7.655,0,0,0,1.969.635,4.805,4.805,0,0,0,.953.064,4.258,4.258,0,0,0,2.223-.508,1.54,1.54,0,0,0,.7-1.4,1.459,1.459,0,0,0-.254-.89,3.624,3.624,0,0,0-.7-.7,5.253,5.253,0,0,0-1.016-.572c-.381-.191-.826-.381-1.27-.572a12.456,12.456,0,0,1-1.334-.763,7.313,7.313,0,0,1-1.08-.89,2.87,2.87,0,0,1-.7-1.08,3.234,3.234,0,0,1-.254-1.4,3.149,3.149,0,0,1,.445-1.716,3.654,3.654,0,0,1,1.207-1.207,4.39,4.39,0,0,1,1.588-.635,9.8,9.8,0,0,1,1.905-.254,8.165,8.165,0,0,1,3.239.508V75.2a5.3,5.3,0,0,0-3.112-.89,5.144,5.144,0,0,0-1.969.445,1.39,1.39,0,0,0-.635.572,1.8,1.8,0,0,0-.254.826,1.76,1.76,0,0,0,.191.826,1.8,1.8,0,0,0,.572.635,6.275,6.275,0,0,0,.953.572,12.367,12.367,0,0,0,1.27.572,10.108,10.108,0,0,1,1.4.826,4.16,4.16,0,0,1,1.143.953,4.494,4.494,0,0,1,.762,1.144,3.464,3.464,0,0,1,.318,1.4,3.088,3.088,0,0,1-.445,1.779,4.167,4.167,0,0,1-1.143,1.207,6.928,6.928,0,0,1-1.715.7,10.508,10.508,0,0,1-2.032.191,4.828,4.828,0,0,1-.889-.064c-.318-.064-.7-.064-1.08-.127a4.758,4.758,0,0,1-1.016-.254A7.449,7.449,0,0,1,54.9,86.189Z"
                                        transform="translate(-20.158 -72.4)"></path>
                                    <path
                                        d="M83.139,86.843H75.2V72.8h7.558v1.97H77.487v4h4.827v1.97H77.487v4.194h5.589v1.906Z"
                                        transform="translate(-27.564 -72.546)"></path>
                                    <path
                                        d="M105.388,81.188c0,3.94-1.842,5.91-5.589,5.91-3.557,0-5.4-1.906-5.4-5.719V72.8h2.286v8.134c0,2.732,1.08,4.13,3.239,4.13,2.1,0,3.112-1.334,3.112-4V72.8h2.35Z"
                                        transform="translate(-34.569 -72.546)"></path>
                                    <path
                                        d="M132.816,86.843H130.53V77.756c0-.763.063-1.652.127-2.732h-.064a5.912,5.912,0,0,1-.381,1.334L126.02,86.907h-1.588l-4.192-10.421a8.185,8.185,0,0,1-.381-1.4H119.8c.063.572.063,1.462.063,2.732v9.087H117.7V72.8H121l3.684,9.4c.254.7.445,1.271.572,1.589h.064c.254-.763.445-1.271.572-1.652l3.747-9.341h3.176V86.843Z"
                                        transform="translate(-43.07 -72.546)"></path>
                                </g>
                                <g fill="#191817" transform="translate(28.963 105.356)">
                                    <path
                                        d="M53.031,174.224a9.154,9.154,0,0,1-2.795.508,4.863,4.863,0,0,1-3.43-1.144,4.471,4.471,0,0,1-1.207-3.177c0-2.732,1.969-4.512,4.827-4.512a4.6,4.6,0,0,1,2.287.445l-.381,1.271a5.133,5.133,0,0,0-1.969-.381,3.146,3.146,0,0,0-.127,6.291,2.6,2.6,0,0,0,1.207-.191v-2.288H49.919v-1.271h3.049v4.448Z"
                                        transform="translate(-45.6 -165.836)"></path>
                                    <path
                                        d="M61.488,165.864v5.084c0,1.716.7,2.478,1.778,2.478,1.143,0,1.842-.763,1.842-2.478v-5.084H66.7v4.956c0,2.669-1.4,3.876-3.493,3.876-2.032,0-3.3-1.144-3.3-3.876V165.8h1.588Z"
                                        transform="translate(-50.817 -165.8)"></path>
                                    <path
                                        d="M73.364,165.991a10.953,10.953,0,0,1,2.286-.191,3.764,3.764,0,0,1,2.477.572,1.774,1.774,0,0,1,.889,1.652,2.058,2.058,0,0,1-1.461,1.906h0a2.259,2.259,0,0,1,1.778,2.161,2.176,2.176,0,0,1-.826,1.779,4.73,4.73,0,0,1-3.239.826,13.718,13.718,0,0,1-1.969-.127v-8.578Zm1.588,3.431h.826c1.08,0,1.715-.508,1.715-1.271,0-.826-.635-1.207-1.651-1.207a2.885,2.885,0,0,0-.889.064Zm0,4c.191,0,.508.064.826.064,1.016,0,1.905-.381,1.905-1.462,0-1.017-.889-1.4-1.969-1.4h-.762Z"
                                        transform="translate(-55.706 -165.8)"></path>
                                    <path
                                        d="M86.514,172.227l-.762,2.478H84.1L86.895,166h2.032l2.858,8.706H90.07l-.762-2.478Zm2.477-1.207-.7-2.16a15.924,15.924,0,0,1-.445-1.652h0a15.92,15.92,0,0,1-.445,1.652l-.7,2.16Z"
                                        transform="translate(-59.647 -165.873)"></path>
                                </g>
                            </svg>
                            <span className="section">
                                <span>{translations[lang].logo[0]}</span>
                                <span>{translations[lang].logo[1]}</span>
                            </span>
                        </div>
                        <div className="buttons">
                            <div className="select mr20">
                                <select onChange={e => setLang(e.target.value)}>
                                    <option value="ru">RU</option>
                                    <option value="en">EN</option>
                                    <option value="az">AZ</option>
                                </select>
                            </div>    
                        </div>
                    </div>
                </div>
            </header>

            <Slider images={[bg1, bg1, bg1]}/>

            {!enabledTour && (
                <>
                    <section className="row auth-block">
                        <div className="container">
                            <h2 className="title-block">{translations[lang].logo.join(' ')}</h2>
                            <button 
                                onClick={showTour} 
                                type="button" className="btn btn-green wide" 
                                style={{margin:'0px auto',display:'block'}}>{translations[lang].viewTour}</button>
                        </div>
                    </section>
                </>
            )}

            {enabledTour && (
                <>
                    <Iframe isIframe={true}/>
                </>
            )}
        </div>
    );
}

export default App;